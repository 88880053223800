"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
require("./style.sass");
var ButtonSwitch = function (_a) {
    var value = _a.value, onClick = _a.onClick, valueType = _a.valueType, firstLabel = _a.firstLabel, secondLabel = _a.secondLabel, isFirstLabelTrue = _a.isFirstLabelTrue, firstBgColor = _a.firstBgColor, firstTextColor = _a.firstTextColor, secondBgColor = _a.secondBgColor, secondTextColor = _a.secondTextColor;
    var _b = (0, react_1.useState)(null), innerValue = _b[0], setInnerValue = _b[1];
    var _c = (0, react_1.useState)({}), leftStyle = _c[0], setLeftStyle = _c[1];
    var _d = (0, react_1.useState)({}), rightStyle = _d[0], setRightStyle = _d[1];
    (0, react_1.useEffect)(function () {
        if (valueType === 'Boolean') {
            setInnerValue(value !== null
                ? value == 1 || value == 0
                    ? value == 1 || value === ''
                        ? true
                        : false
                    : null
                : null);
        }
        else {
            setInnerValue(value === '' ? true : value);
        }
        setLeftStyle({
            backgroundColor: firstBgColor ? firstBgColor : '#3f704d',
            color: firstTextColor ? firstTextColor : '#fff',
        });
        setRightStyle({
            backgroundColor: secondBgColor ? secondBgColor : '#d30000',
            color: secondTextColor ? secondTextColor : '#fff',
        });
    }, [firstBgColor, firstTextColor, secondBgColor, secondTextColor, value, valueType]);
    var onBtnClick = function (val) {
        if (valueType === 'Boolean') {
            setInnerValue(val != null ? !!val : null);
        }
        else {
            setInnerValue(val);
        }
        onClick(val);
    };
    var selectStyleLeft = function () {
        if (valueType === 'boolean' && innerValue === true) {
            return leftStyle;
        }
        else if (firstLabel && innerValue === firstLabel) {
            return leftStyle;
        }
        return {};
    };
    var selectStyleRight = function () {
        if (valueType === 'boolean' && innerValue === false) {
            return rightStyle;
        }
        else if (secondLabel && innerValue === secondLabel) {
            return rightStyle;
        }
        return {};
    };
    var t = (0, react_i18next_1.useTranslation)().t;
    return (react_1.default.createElement("div", { className: 'button-switch' },
        react_1.default.createElement("button", { style: innerValue !== null && (innerValue === true || innerValue == firstLabel)
                ? leftStyle
                : {}, onClick: function () { return onBtnClick(valueType === 'Boolean' ? 1 : firstLabel); } }, firstLabel ? firstLabel : t('yes')),
        react_1.default.createElement("button", { style: innerValue !== null && (innerValue === false || innerValue == secondLabel)
                ? rightStyle
                : {}, onClick: function () { return onBtnClick(valueType === 'Boolean' ? 0 : secondLabel); } }, firstLabel ? firstLabel : t('no'))));
};
exports.default = ButtonSwitch;
