"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientRoutes = exports.AdminRoutes = exports.NonAuthRoutes = exports.AuthRoutes = void 0;
var AuthRoutes;
(function (AuthRoutes) {
    AuthRoutes["dashboard"] = "/dashboard";
    AuthRoutes["network"] = "/network";
})(AuthRoutes = exports.AuthRoutes || (exports.AuthRoutes = {}));
var NonAuthRoutes;
(function (NonAuthRoutes) {
    NonAuthRoutes["login"] = "/";
    NonAuthRoutes["unauthorized"] = "/unauthorized";
})(NonAuthRoutes = exports.NonAuthRoutes || (exports.NonAuthRoutes = {}));
var AdminRoutes;
(function (AdminRoutes) {
    AdminRoutes["dashboard"] = "/admin/dashboard";
    AdminRoutes["governance"] = "/admin/governance";
    AdminRoutes["health"] = "/admin/health";
    AdminRoutes["education"] = "/admin/education";
    AdminRoutes["social"] = "/admin/social";
    AdminRoutes["finance"] = "/admin/finance";
    AdminRoutes["work"] = "/admin/work";
    AdminRoutes["report"] = "/admin/report";
})(AdminRoutes = exports.AdminRoutes || (exports.AdminRoutes = {}));
var ClientRoutes;
(function (ClientRoutes) {
    ClientRoutes["dashboard"] = "/agent/dashboard";
    ClientRoutes["governance"] = "/agent/governance";
    ClientRoutes["health"] = "/agent/health";
    ClientRoutes["education"] = "/agent/education";
    ClientRoutes["social"] = "/agent/social";
    ClientRoutes["finance"] = "/agent/finance";
    ClientRoutes["work"] = "/agent/work";
    ClientRoutes["report"] = "/agent/report";
})(ClientRoutes = exports.ClientRoutes || (exports.ClientRoutes = {}));
