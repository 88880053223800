"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AuthActionTypes = {
    EMAIL_CHANGED: 'email_changed',
    PASSWORD_CHANGED: 'password_changed',
    USER_LOGIN: 'user_login',
    LENGTH_ERROR: 'length_error',
    USER_LOGIN_SUCCESS: 'user_login_success',
    USER_LOGIN_FAIL: 'user_login_fail',
    USER_DETAILS: 'user_details',
    USER_DETAILS_FAIL: 'user_details_fail',
    LOGOUT: 'logout',
    LOGOUT_REQUEST: 'logout_request',
    LOGOUT_FAIL: 'logout_fail',
    USER_DETAIL_REQUEST: 'user_detail_request',
    GET_ALLDETAIL_USER_REQUEST: 'get.alldetail.user.request',
    GET_ALLDETAIL_USER_SUCCESS: 'get.alldetail.user.success',
    GET_ALLDETAIL_USER_SUCCESS2: 'get.alldetail.user.success2',
    GET_ALLDETAIL_USER_FAIL: 'get.alldetail.user.fail',
    GET_USERDETAIL_UMVA_IDS_REQUEST: 'get.userdetail.umva.ids.request',
    GET_USERDETAIL_UMVA_IDS_SUCCESS: 'get.userdetail.umva.ids.success',
    GET_USERDETAIL_UMVA_IDS_FAIL: 'get.userdetail.umva.ids.fail',
    RESET: 'reset',
    SET_USER: 'set_user',
    CHECK_USER_EXIST_REQUEST: 'check.user.exist.request',
    CHECK_USER_EXIST_SUCCESS: 'check.user.exist.success',
    CHECK_USER_EXIST_FAIL: 'chech.user.exist.fail',
    OFFLINE_LOGIN_ERROR: 'offline_login_error',
    OFFLINE_LOGIN_SUCCESS: 'offline_login_success',
};
exports.default = AuthActionTypes;
