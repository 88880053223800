"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeObjectFacility = exports.mergeObject = void 0;
var mergeObject = function (obj) {
    if (obj === void 0) { obj = {}; }
    var result = [];
    result = Object.keys(obj)
        .reduce((function (hash) {
        return function (r, k) {
            obj[k].forEach(function (o) {
                if (!hash[o.id]) {
                    hash[o.id] = {};
                    r.push(hash[o.id]);
                }
                Object.keys(o).forEach(function (l) {
                    hash[o.id][l] = o[l];
                });
            });
            return r;
        };
    })(Object.create(null)), [])
        .sort(function (a, b) {
        return a['id'] - b['id'];
    });
    return result;
};
exports.mergeObject = mergeObject;
var mergeObjectFacility = function (obj) {
    if (obj === void 0) { obj = {}; }
    var result = [];
    if (obj.facility_material_type.length > 0) {
        obj.facility_material_type.map(function (k) {
            obj.materialTypeArr.map(function (l, index) {
                if (k.material_type_id === l.id) {
                    //   result.push({ ...k, ...l });
                    result[index] = __assign(__assign({}, k), l);
                    return null;
                }
                else {
                    //   result.push(l);
                    result[index] = __assign(__assign({}, result[index]), l);
                }
            });
        });
    }
    else {
        result = obj.materialTypeArr;
    }
    return result;
    // result = Object.keys(obj)
    //     .reduce(
    //         (function (hash) {
    //             return function (r, k) {
    //                 console.log({ r, k, hash, obj });
    //                 obj[k].forEach(function (o) {
    //                     if (!hash[o.id]) {
    //                         hash[o.id] = {};
    //                         r.push(hash[o.id]);
    //                     }
    //                     Object.keys(o).forEach(function (l) {
    //                         hash[o.id]["id"] = o[l];
    //                     });
    //                 });
    //                 return r;
    //             };
    //         })(Object.create(null)),
    //         []
    //     )
    //     .sort((a, b) => {
    //         return a["id"] - b["material_type_id"];
    //     });
    // return result;
};
exports.mergeObjectFacility = mergeObjectFacility;
