"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStateWithPromise = void 0;
var react_1 = require("react");
var useStateWithPromise = function (initialState) {
    var _a = (0, react_1.useState)(initialState), state = _a[0], setState = _a[1];
    var resolverRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (resolverRef.current) {
            resolverRef.current(state);
            resolverRef.current = null;
        }
    }, [state]);
    var handleSetState = (0, react_1.useCallback)(function (stateAction) {
        setState(stateAction);
        return new Promise(function (resolve) {
            resolverRef.current = resolve;
        });
    }, [setState]);
    return [state, handleSetState];
};
exports.useStateWithPromise = useStateWithPromise;
