"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var general_1 = require("../../../../lib/general");
var BooleanType = function (_a) {
    var data = _a.data, onClick = _a.onClick;
    var _b = (0, react_i18next_1.useTranslation)(), t = _b.t, i18n = _b.i18n;
    var onChangeData = function (e) {
        onClick === null || onClick === void 0 ? void 0 : onClick('reporting_type', JSON.stringify(e.target.value));
    };
    return (react_1.default.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
        react_1.default.createElement("div", { style: { display: 'flex' } },
            react_1.default.createElement("input", { type: 'radio', value: 'display_yes', name: 'boolean', checked: (data === null || data === void 0 ? void 0 : data.toString()) === 'display_yes', onChange: onChangeData }),
            t('display_yes')),
        react_1.default.createElement("div", { style: { display: 'flex' } },
            react_1.default.createElement("input", { type: 'radio', value: 'count_of_yes', name: 'boolean', checked: (data === null || data === void 0 ? void 0 : data.toString()) === 'count_of_yes', onChange: onChangeData }),
            t('count_of_yes'))));
};
var NumberType = function (_a) {
    var data = _a.data, onClick = _a.onClick;
    var _b = (0, react_i18next_1.useTranslation)(), t = _b.t, i18n = _b.i18n;
    var _c = (0, react_1.useState)([]), checked = _c[0], setChecked = _c[1];
    (0, react_1.useEffect)(function () {
        data && setChecked(__spreadArray([], data, true));
    }, [data]);
    var onChangeData = function (e) {
        var values = __spreadArray([], checked, true);
        // console.log({ values });
        if (values.findIndex(function (element) { return element === e.target.value; }) >= 0) {
            if (e.target.checked === false) {
                values.splice(values.findIndex(function (element) { return element === e.target.value; }), 1);
            }
        }
        else {
            // console.log("check");
            if (e.target.checked) {
                values.push(e.target.value);
            }
        }
        setChecked(values);
        onClick === null || onClick === void 0 ? void 0 : onClick('reporting_type', JSON.stringify(values));
        // onClick?.('reporting_type',values)
    };
    // if(values.findIndex(e.target.value))
    // {
    //     values[values.findIndex(e.target.value)]
    // }
    // onClick?.('reporting_type',e.target.value)
    // }
    return (react_1.default.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
        react_1.default.createElement("div", { style: { display: 'flex' } },
            react_1.default.createElement("input", { type: 'checkbox', checked: (checked === null || checked === void 0 ? void 0 : checked.includes('Count')) ? true : false, value: 'Count', name: 'boolean', onChange: onChangeData }),
            t('count')),
        react_1.default.createElement("div", { style: { display: 'flex' } },
            react_1.default.createElement("input", { type: 'checkbox', checked: (checked === null || checked === void 0 ? void 0 : checked.includes('Total')) ? true : false, value: 'Total', name: 'boolean', onChange: onChangeData }),
            t('total')),
        react_1.default.createElement("div", { style: { display: 'flex' } },
            react_1.default.createElement("input", { type: 'checkbox', checked: (checked === null || checked === void 0 ? void 0 : checked.includes('Average')) ? true : false, value: 'Average', name: 'boolean', onChange: onChangeData }),
            t('average'))));
};
var TextType = function () {
    var _a = (0, react_i18next_1.useTranslation)(), t = _a.t, i18n = _a.i18n;
    return react_1.default.createElement(react_1.default.Fragment, null);
};
var DropdownType = function () {
    var _a = (0, react_i18next_1.useTranslation)(), t = _a.t, i18n = _a.i18n;
    return react_1.default.createElement(react_1.default.Fragment, null);
};
var ReportType = function (_a) {
    var data = _a.data, type = _a.type, onClick = _a.onClick;
    // console.log("data", data ? data : "");
    return (react_1.default.createElement(react_1.default.Fragment, null,
        type
            ? type === 'Text' && (react_1.default.createElement(TextType, { data: (0, general_1.isJsonString)(data) ? JSON.parse(data) : data, onClick: onClick }))
            : '',
        type
            ? type === 'Dropdown' && (react_1.default.createElement(DropdownType, { data: (0, general_1.isJsonString)(data) ? JSON.parse(data) : data }))
            : '',
        type
            ? type === 'Number' && (react_1.default.createElement(NumberType, { data: (0, general_1.isJsonString)(data) ? JSON.parse(data) : data, onClick: onClick }))
            : '',
        type
            ? type === 'Boolean' && (react_1.default.createElement(BooleanType, { data: (0, general_1.isJsonString)(data) ? JSON.parse(data) : data, onClick: onClick }))
            : ''));
};
exports.default = ReportType;
