// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/landing.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon path{fill:#fff}.icon_orange path{fill:#ff8a65}.layout{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0 fixed,#fffdfc;background-size:contain;display:flex;flex:1;flex-direction:column;justify-content:flex-start;min-height:100vh;padding:0 .625rem}.card{background:hsla(0,0%,100%,.95);border-radius:.3125rem;box-shadow:0 4px 8px 0 rgba(0,0,0,.2);flex:1}.card .container{padding:.625rem}", "",{"version":3,"sources":["webpack://./resources/js/src/components/Theme/Sass/variable.sass","webpack://./resources/js/src/components/Theme/layout.sass"],"names":[],"mappings":"AAeI,WACI,SCdR,CDiBI,kBACI,YCdR,CAJA,QAEI,8EAAA,CAEA,uBAAA,CAHA,YAAA,CAIA,MAAA,CAFA,qBAAA,CAGA,0BAAA,CACA,gBAAA,CACA,iBAOJ,CALA,MAII,8BAAA,CADA,sBAAA,CAFA,qCAAA,CACA,MAUJ,CANI,iBACI,eAQR","sourcesContent":["$fontSize: 16px\n$bodyBgColor: #edf0f5\n$primaryColor1: #ff8a65\n$primaryColor2: #ff965d\n$primaryColor3: #fcc47d\n\n$primaryTextColor1: #ff8a65\n$primaryTextColor2: #ff965d\n$primaryTextColor3: #ffad52\n\n$subButtonBg: #f5f5f5\n\n$textColor: #333333\n$borderColor: #ecebed\n.icon\n    path\n        fill: #fff\n\n.icon_orange\n    path\n        fill: $primaryColor1\n","@import \"./Sass/variable\"\n@import \"Sass/mixins\"\n.layout\n    display: flex\n    background: url(\"../../assets/landing.png\") no-repeat top left fixed, #FFFDFC\n    flex-direction: column\n    background-size: contain\n    flex: 1\n    justify-content: flex-start\n    min-height: 100vh\n    padding: 0 rem(10)\n\n.card\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)\n    flex: 1\n    border-radius: rem(5)\n    background: rgba(255, 255, 255, 0.95)\n\n    .container\n        padding: rem(10)\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
